import React, { useState, useEffect } from 'react';
import './SearchPanel.css'; // Asegúrate de tener los estilos actualizados
import _ from 'lodash';

const SearchPanel = ({ onSearch, onResetSearch}) => {
  const [title, setTitle] = useState('');
  const [poster, setPoster] = useState('');
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [genre, setGenre] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false); // Nuevo estado para controlar la visibilidad
  const [reset, setReset] = useState(false);	
  const debounceSearch = _.debounce(onSearch, 300);

  const handleResetFilters = () => {
    setReset(true);
    //setSearchParams({});
    setCurrentPage(1);
    setTimeout(() => setReset(false), 0);
    onResetSearch();	  
  };

  useEffect(() => {
    if (reset) {
      setTitle('');
      setPoster('');
      setCategory([]);
      setGenre('');
    }	  
    debounceSearch({ title, poster, category, genre });
  }, [title, poster, category, genre]);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    const updatedCategory = category.includes(selectedCategory)
      ? category.filter((cat) => cat !== selectedCategory)
      : [...category, selectedCategory];

    setCategory(updatedCategory);
    onSearch({ title, poster, category: updatedCategory, genre });
  };

  useEffect(() => {
    if (reset) {
      const checkboxes = document.querySelectorAll('.search-field input[type="checkbox"]');
      checkboxes.forEach(checkbox => checkbox.checked = false); // Desmarcar todas las categorías visualmente
    }
  }, [reset]);

  const toggleSearchPanel = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  return (
    <div className="search-panel-container">
      <div className="search-panel-buttons">
        <button onClick={toggleSearchPanel} className="search-panel-toggle">
          {isSearchVisible ? 'Hide Search' : 'Show Search'}
        </button>
	<div>&nbsp;</div>  
	<button onClick={handleResetFilters} className="search-panel-reset">
          <i className="fa fa-undo" /> Reset Filters
        </button>
      </div>

      {isSearchVisible && (
        <div className="search-panel">
          <div className="column">
            <div className="search-field">
              <input
                type="text"
                placeholder="Search Title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <i className="search-icon title-icon" />
            </div>
            <div className="search-field">
              <input
                type="text"
                placeholder="Search Sender..."
                value={poster}
                onChange={(e) => setPoster(e.target.value)}
              />
              <i className="search-icon poster-icon" />
            </div>
          </div>
          <div className="column">
            <div className="search-field">
              <label>
                <input
                  type="checkbox"
                  value="0"
                  onChange={handleCategoryChange}
                /> Image
              </label>
              <label>
                <input
                  type="checkbox"
                  value="1"
                  onChange={handleCategoryChange}
                /> Sound
              </label>
              <label>
                <input
                  type="checkbox"
                  value="2"
                  onChange={handleCategoryChange}
                /> Games
              </label>
              <label>
                <input
                  type="checkbox"
                  value="3"
                  onChange={handleCategoryChange}
                /> Applications
              </label>
            </div>
            <div className="search-field">
              <input
                type="text"
                placeholder="Search Genre..."
                value={genre}
                onChange={(e) => setGenre(e.target.value)}
              />
              <i className="search-icon genre-icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPanel;
