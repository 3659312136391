import React from 'react';
import './CommentsList.css'; // Archivo CSS para los estilos
import parse from 'html-react-parser';
//https://newspotweb.xyz/?page=getimage&image[type]=avatar&image[size]=32&image[md5]=f424de0169c10de3389e8ea15cac1d11
const Comment = ({ avatar, user, userId, time, message }) => {	
  return (
   <div >	  
    <li className="comment">
      <img className="comment-avatar" src={avatar} alt="Avatar" />
      <strong>
        Posted by <span className="user">{user}</span> ({' '}
        <a className="user-id" href={`/search?userid=${userId}`} target="_blank" rel="noopener noreferrer">
          {userId}
        </a>{' '}
        ) @ {time}{' '}
      </strong>
      <br />
      <p className="comment-message">{message}</p>
    </li>
   </div>
  );
};
const CommentsList = ({ comments }) => {
  return (
    <ul className="comments-list">
      {comments.map((comment, index) => (
        <Comment
          key={index}
          user={comment.user}
          userId={comment.user_id}
          time={comment.time}
          avatar={parse(comment.avatar)}
          message={parse(comment.message)}
        />
      ))}
    </ul>
  );
};

export default CommentsList;

