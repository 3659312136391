import React from 'react';
import { Link } from 'react-router-dom';
import './VisitedPosts.css'; // Crear un archivo CSS separado para estilos

const VisitedPostsList = () => {
  const visitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];

  return (
    <div className="visited-posts-container">
      <h3 className="visited-posts-header">Last Viewed Posts</h3>
      <ul className="visited-posts-list">
        {visitedPosts.map((post) => (
          <li key={post.id} className="visited-post-item">
            <Link to={`/post/${post.id}`} className="visited-post-link">
              <img 
                src={post.imageurl} 
                alt={post.title} 
                className="visited-post-thumbnail"
              />
              <span className="visited-post-title">
                {post.title.length > 20 ? `${post.title.substring(0, 20)}...` : post.title}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VisitedPostsList;

