import React, { useEffect } from 'react';
import './Pagination.css';

const Pagination = ({ total, perPage, currentPage, onPageChange, reset }) => {
  const totalPages = Math.ceil(total / perPage);

  // Always call useEffect, but conditionally execute logic
  useEffect(() => {
    if (totalPages > 1) {
      localStorage.setItem('currentPage', currentPage);
    }
  }, [currentPage, totalPages]);

  if (totalPages === 1) return null;

  const handleFirstPage = () => onPageChange(1);
  const handleLastPage = () => onPageChange(totalPages);
  const handleNextPage = () => onPageChange(currentPage + 1);
  const handlePrevPage = () => onPageChange(currentPage - 1);

  const generatePageNumbers = () => {
    const pages = [];
    const delta = 2; // Controls how many pages are shown at once
    const range = [];

    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      pages.push(<span key="dots1">...</span>);
    }

    range.forEach(page => {
      pages.push(
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={page === currentPage ? 'active' : ''}
        >
          {page}
        </button>
      );
    });

    if (currentPage + delta < totalPages - 1) {
      pages.push(<span key="dots2">...</span>);
    }

    return pages;
  };

  return (
    <div className="pagination">
      <button onClick={handleFirstPage} disabled={currentPage === 1}>
        First
      </button>
      <button onClick={handlePrevPage} disabled={currentPage === 1}>
        Prev
      </button>
      <button
        onClick={() => onPageChange(1)}
        className={currentPage === 1 ? 'active' : ''}
      >
        1
      </button>
      {generatePageNumbers()}
      <button
        onClick={() => onPageChange(totalPages)}
        className={currentPage === totalPages ? 'active' : ''}
      >
        {totalPages}
      </button>
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next
      </button>
      <button onClick={handleLastPage} disabled={currentPage === totalPages}>
        Last
      </button>
    </div>
  );
};

export default Pagination;
